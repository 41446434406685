import React from "react";
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Button,
  Box
} from "@mui/material";
import HomeSearchVideo from "../videos/uiw-search-video.mp4";
import "../App.css";
import GitHubIcon from "@mui/icons-material/GitHub";
import MicrosoftVideo from "../videos/outlook-onedrive-video.mp4";
import CanvasVideo from "../videos/canvas-cards-video.mp4";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#a2a3ac", // Your chosen primary color
    },
    secondary: {
      main: "#7986cb", // Your chosen secondary color
    },
  },
});

function Projects() {
  return (
    <ThemeProvider theme={theme}>
      <div>
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h1" color="text.primary" className="typography-title">Projects</Typography>
        </Box>
        <div>
          <Card className="project-card">
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                University of the Incarnate Word Search
              </Typography>
              <CardMedia component="video" controls autoPlay loop muted>
                <source src={HomeSearchVideo} type="video/mp4" />
              </CardMedia>
              <Typography variant="body2" color="text.secondary" sx={{ marginTop: theme.spacing(2) }} style={{ color: '#FFFFFF' }}>
                In a pivotal project I led from development to production, we successfully integrated our Banner database with Algolia, introducing a unified search feature across all sites for the first time. By employing Insomnia, JavaScript, and XML/JSON, we developed an advanced search bar and mega menu in Cascade CMS, significantly enhancing site navigation and user engagement. The project demonstrated our dedication to utilizing state-of-the-art technology to improve user experience, including a header redesign inspired by Amazon's layout.
              </Typography>
              <Box sx={{ display: 'flex', gap: theme.spacing(2), mt: theme.spacing(2) }} target="_blank"
                rel="noopener noreferrer">
                <Button variant="contained" color="primary" className="project-button" href="https://github.com/avc0021/algolia" target="_blank"
                  rel="noopener noreferrer">
                  <GitHubIcon />
                </Button>
                <Button variant="contained" color="primary" className="project-button" href="https://www.uiw.edu/" target="_blank"
                  rel="noopener noreferrer">
                  <OpenInNewIcon />
                </Button>
              </Box>
            </CardContent>
          </Card>

          <Card className="project-card">
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Microsoft Outlook and OneDrive Extension
              </Typography>
              <CardMedia component="video" controls autoPlay loop muted>
                <source src={MicrosoftVideo} type="video/mp4" />
              </CardMedia>
              <Typography variant="body2" color="text.secondary" sx={{ marginTop: theme.spacing(2) }} style={{ color: '#FFFFFF', fontStyle: 'italic' }}>
                * Due to SSO security and exclusive access for the Incarnate Word community, a video demo is provided.
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ marginTop: theme.spacing(2) }} style={{ color: '#FFFFFF' }}>
                Using the power of Ellucian Experience and its React SDK from the Productivity Extensions I created an application specifically designed for the Incarnate Word University.
                Our custom-built extension seamlessly integrates with the Ellucian Experience platform, bringing a significant enhancement to the user experience by providing immediate access to Outlook and OneDrive accounts for both employees and students of Incarnate Word University.
              </Typography>
              <Box sx={{ display: 'flex', gap: theme.spacing(2), mt: theme.spacing(2) }}>
                <Button
                  variant="contained"
                  className="project-button"
                  color="primary"
                  href="https://github.com/avc0021/EEDev"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <GitHubIcon href="https://github.com/avc0021/EEDev" />
                </Button>
                {/* <Button
                  openInNewIcon="OpenInNewIcon"
                  variant="contained"
                  className="project-button"
                  color="primary"
                  href="https://experience.elluciancloud.com/uotiw/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <OpenInNewIcon href="https://experience.elluciancloud.com/uotiw/" />
                </Button> */}
              </Box>
            </CardContent>
          </Card>

          <Card className="project-card">
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Custom Canvas Instructure Extension
              </Typography>
              <CardMedia component="video" controls autoPlay loop muted>
                <source src={CanvasVideo} type="video/mp4" />
              </CardMedia>
              <Typography variant="body2" color="text.secondary" sx={{ marginTop: theme.spacing(2) }} style={{ color: '#FFFFFF', fontStyle: 'italic' }}>
                * Due to SSO security and exclusive access for the Incarnate Word community, a video demo is provided.
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ marginTop: theme.spacing(2) }} style={{ color: '#FFFFFF' }}>
                This custom card for the Ellucian Experience platform enhances student engagement by offering a real-time overview of courses and grades. Integrated seamlessly with the React SDK, it connects to Canvas through AWS Lambda to fetch current course enrollments and grades. This feature allows students to monitor their academic progress directly within the Ellucian Experience, helping them stay informed about their performance and identify areas for improvement quickly.
              </Typography>
              <Box sx={{ display: 'flex', gap: theme.spacing(2), mt: theme.spacing(2) }}>
                <Button
                  variant="contained"
                  className="project-button"
                  color="primary"
                  href="https://github.com/avc0021/canvas-courses"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <GitHubIcon href="https://github.com/avc0021/canvas-courses" />
                </Button>
              </Box>
              <Typography variant="body2" color="text.secondary" sx={{ marginTop: theme.spacing(2) }} style={{ color: '#FFFFFF' }}>
                The card also features a dedicated functionality for managing to-do items, with a direct link to Canvas for efficient task management. By providing a clear list of pending assignments and quizzes, it helps students keep track of their responsibilities and deadlines. A strategically placed button at the card's bottom facilitates immediate navigation to Canvas, enabling students to address their to-do items promptly, thus enhancing their ability to plan and prioritize their academic workload.
              </Typography>
              <Box sx={{ display: 'flex', gap: theme.spacing(2), mt: theme.spacing(2) }}>
                <Button
                  variant="contained"
                  className="project-button"
                  color="primary"
                  href="https://github.com/avc0021/canvas-to-do"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <GitHubIcon href="https://github.com/avc0021/canvas-to-do" />
                </Button>
              </Box>
            </CardContent>
          </Card>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default Projects;
